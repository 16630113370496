import React from 'react';

import { i18n } from '../../lang/pl';
import { AddShoppingCartOutlined } from '@material-ui/icons';
import { Fab, useScrollTrigger } from '@material-ui/core';

import { useStyles } from './BuyNow.styles';
import { useLocation } from 'react-router-dom';
import { Routes } from '../../router/routes';

const BuyNow = () => {
  const trigger = !useScrollTrigger({
    threshold: 200,
  });
  const classes = useStyles();
  const { pathname } = useLocation();

  return trigger && pathname === Routes.index ? (
    <Fab variant={'extended'} className={classes.fab} href={'#buy'}>
      <AddShoppingCartOutlined />
      {i18n.buyNow.text}
    </Fab>
  ) : null;
};

export default BuyNow;
