import { useMemo } from 'react';

import { regExp } from '../../config/regExp';
import { IValidator } from '../../models/Forms';

export const useEmailValidator = (requiredMsg: string, correctMsg: string): IValidator =>
  useMemo(
    () => ({
      required: {
        value: true,
        message: requiredMsg,
      },
      pattern: {
        value: regExp.email,
        message: correctMsg,
      },
    }),
    [requiredMsg, correctMsg]
  );
