import React, { Children } from 'react';

import { Box } from '@material-ui/core';

import { ISimpleGridProps } from './SimpleGrid';

import { useStyles } from './SimpleGrid.styles';

export const SimpleGrid = ({ children, component = 'section', spacing }: ISimpleGridProps) => {
  const classes = useStyles({
    spacing,
  });

  const items = Children.map(children, (child, index) =>
    child ? <Box component={component} className={classes.item} children={child} /> : null
  )?.filter(Boolean);

  return <Box className={classes.root} children={items} />;
};
