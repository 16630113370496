import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto',
      minHeight: '90vh',
      position: 'relative',
    },
    arc: {
      position: 'absolute',
      willChange: 'transform',
    },
    image: {
      height: '100%',
      margin: '0 auto',
      maxHeight: '80vh',
      maxWidth: 'calc(100% - 3rem)',
      zIndex: -1,
    },
    fixed: {
      position: 'fixed',
    },
  })
);
