import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderRadius: theme.shape.borderRadius,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    image: {
      height: '100%',
      objectFit: 'contain',
      width: '100%',
    },
    imageContainer: {
      height: 32,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: '100%',
    },
  })
);
