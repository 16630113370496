import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'row wrap',
    },
    avatar: {
      borderRadius: 0,
    },
    item: {
      flex: '1 1 50%',
    },
    itemMobile: {
      flex: '1 1 100%',
    },
  })
);
