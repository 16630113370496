import React from 'react';

import { AppBar, Slide, Toolbar } from '@material-ui/core';
import { useScrollTrigger } from '@material-ui/core';

import { Brand } from '../../reusable/Brand';

import { BurgerMenu } from '../../BurgerMenu';
import { CartIcon } from '../../reusable/CartIcon';

import { useStyles } from './Navbar.styles';

export const Navbar = () => {
  const trigger = useScrollTrigger();
  const classes = useStyles();

  return (
    <Slide appear={false} direction={'down'} in={!trigger}>
      <AppBar color={'default'}>
        <Toolbar className={classes.toolbar}>
          <BurgerMenu />
          <Brand />
          <CartIcon />
        </Toolbar>
      </AppBar>
    </Slide>
  );
};
