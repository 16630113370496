import React from 'react';

import { Box, IconButton, ListItem, ListItemText } from '@material-ui/core';
import { AddCircleOutline, DeleteForeverOutlined, RemoveCircleOutline } from '@material-ui/icons';
import cn from 'classnames';

import { useCartItems } from '../../state/cart/hooks/useCartItems';
import { ICartItemProps } from './CartItem';
import { Texts } from '../../config/texts';

import { useStyles } from './CartItem.styles';
import { i18n } from '../../lang/pl';

export const CartItem = ({ index, item }: ICartItemProps) => {
  const { addToCart, decrementCart, removeFromCart } = useCartItems();
  const classes = useStyles();

  const handleAdd = () => addToCart(item.id);
  const handleDelete = () => removeFromCart(item.id);
  const handleRemove = () => (item.count > 1 ? decrementCart(item.id) : removeFromCart(item.id));

  return (
    <ListItem className={cn(classes.root, index !== 0 && classes.itemSpacing)}>
      <Box display={'flex'} flexDirection={'column'}>
        <IconButton onClick={handleAdd} aria-label={'delete'} edge={'start'}>
          <AddCircleOutline color={'primary'} />
        </IconButton>
        <IconButton onClick={handleRemove} aria-label={'delete'} edge={'start'}>
          <RemoveCircleOutline color={'error'} />
        </IconButton>
      </Box>
      <ListItemText
        primary={item.flavour}
        primaryTypographyProps={{
          className: classes.title,
          variant: 'h5',
          color: 'primary',
        }}
      />
      <ListItemText
        primary={`${item.price}\xA0${Texts.currency}`}
        primaryTypographyProps={{
          align: 'right',
          variant: 'h5',
        }}
        secondary={item.count + i18n.cartItem.bottleCapacity}
        secondaryTypographyProps={{
          align: 'right',
        }}
      />
      <IconButton onClick={handleDelete} edge={'end'} aria-label={'delete'}>
        <DeleteForeverOutlined />
      </IconButton>
    </ListItem>
  );
};
