export const i18n = {
  about: {
    title: 'MAJESTEA to warzona tradycyjnymi rzemieślniczymi metodami kombucha.',
    text: 'Czyli święta herbata. Powstała przez fermentację z selektywnie wybranych liści herbaty prosto z gór Xinchang. Jest naturalnym probiotykiem poprawiającym pracę układu pokarmowego i odpornościowego, wypełniona po brzegi witaminami, minerałami oraz antyoksydantami. Rozpieszcza ciało i umysł. Przygotowana w zgodzie z naturą to nie tylko niebiański smak, ale również objawienie orzeźwiającego zdrowia zamkniętego w szklanej butelce.',
  },
  backButton: {
    label: 'Powrót',
  },
  buyNow: {
    text: ' Zamów Majestea!',
  },
  cart: {
    total: 'Razem:\xA0',
    shipping: 'Dostawa:\xA0',
    vat: 'VAT:\xA0',
    payment: 'Do zapłaty:\xA0',
    cartEmpty: 'Twój koszyk jest pusty.',
    goShopping: 'Kliknij w „Kombucha", aby przejść do zakupów.',
    cart: 'Koszyk',
    nextButtonLabel: 'Dalej',
    review: 'Sprawdź swoje zamówienie i wybierz dostawę, aby przejść do kolejnego etapu',
    checkout: 'Podaj swoje dane',
    last: 'Użyjemy ich, aby dostarczyć do Ciebie picie! Guzik na dole strony przekieruje Cię na stronę PayU - nie zamykaj okna przeglądarki, aż do zakończenia procesu płatności.',
  },
  cartItem: {
    bottleCapacity: '\xA0×\xA0250ml',
  },
  coupon: {
    add: 'Dodaj',
    added: 'Kod zniżkowy został dodany.',
    discount: 'Rabat: ',
    error: 'Kod jest nieprawidłowy, lub przypisany do innego adresu e-mail',
    gotCoupon: 'Posiadam kod zniżkowy',
  },
  errors: {
    100: {
      title: 'Ups, coś poszło nie tak.',
      text: 'Spróbuj odświeżyć stronę lub przejść do strony głównej. W razie dalszych problemów, skontaktuj się z obsługą sklepu.',
    },
    404: {
      title: 'Strona o podanym adresie nie istnieje.',
      text: 'Sprawdź czy adres strony na pewno jest poprawny. W razie dalszych problemów skontaktuj się z obsługą sklepu pod adresem sklep@majesteakombucha.pl.',
    },
    501: {
      title: 'Ups! Coś poszło nie tak z płatnością.',
      text: 'Konto nie zostało obciążone, spróbuj jeszcze raz, lub skontaktuj się z obsługą sklepu',
    },
  },
  forms: {
    companyName: 'Nazwa firmy',
    coupon: 'Kod zniżkowy',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    nip: 'NIP',
    street: 'Ulica',
    streetNumber: 'Numer',
    zip: 'Kod pocztowy',
    city: 'Miasto',
    email: 'E-mail',
    phone: 'Numer telefonu',
    invoice: 'Potrzebuję fakturę',
    message: 'Uwagi do zamówienia',
  },
  kombuchas: {
    flavs: 'Nasze smaki',
    find: 'Znajdź najlepszy dla siebie',
  },
  orderStatus: {
    confirmTitle: 'Potwierdzamy złożenie zamówienia, otrzymało ono id ',
    confirmText:
      'Płatność została potwierdzona, dziękujemy za zamówienie w sklepie Majestea, przystępujemy do jego realizacji i zapraszamy ponownie!',
  },
  shipping: {
    text: 'Sposób dostawy',
  },
  userData: {
    dataForm: 'Dane klienta',
    pay: 'Płacę',
    terms:
      'Składając zamówienie akceptujesz regulamin, oraz wyrażasz zgodę na przetwarzanie danych osobowych i politykę prywatności, których treść jest dostępna ',
    termsLink: 'pod tym linkiem.',
  },
  validators: {
    city: 'Podaj miasto',
    companyName: 'Podaj nazwę firmy',
    couponCode: 'Podaj kod zniżkowy',
    email: 'Podaj e-mail',
    emailCorrect: 'Podaj poprawny e-mail',
    firstName: 'Podaj imię',
    lastName: 'Podaj nazwisko',
    nip: 'Podaj NIP',
    nipCorrect: 'Podaj poprawny NIP',
    phone: 'Podaj numer telefonu',
    street: 'Podaj ulicę',
    streetNumber: 'Numer domu/mieszkania',
    streetNumberCorrect: 'Podaj poprawny numer',
    terms: 'Brakuje zgody',
    zip: 'Podaj kod pocztowy',
    zipCorrect: 'Podaj poprawny kod',
  },
};
