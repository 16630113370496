import React, { Suspense } from 'react';

import { SimpleLoading } from '../../components/reusable/SimpleLoading';
import { MainContainer } from '../../components/layout/MainContainer';
import { OrderStatus } from '../../components/OrderStatus';

const loading = <SimpleLoading />;

export const OrderStatusPage = () => (
  <MainContainer>
    <Suspense fallback={loading}>
      <OrderStatus />
    </Suspense>
  </MainContainer>
);
