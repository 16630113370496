import React, { Suspense } from 'react';

import { SimpleLoading } from '../../components/reusable/SimpleLoading';
import { MainContainer } from '../../components/layout/MainContainer';
import { Header } from '../../components/Header';
import { About } from '../../components/About';
import { SimpleGrid } from '../../components/layout/SimpleGrid';
import { Properties } from '../../components/Properties';
import { Kombuchas } from '../../components/Kombuchas';

const loading = <SimpleLoading />;

export const MainPage = () => (
  <MainContainer>
    <Header />
    <SimpleGrid spacing={10}>
      <About />
      <Properties />
      <Suspense fallback={loading}>
        <Kombuchas />
      </Suspense>
    </SimpleGrid>
  </MainContainer>
);
