import React from 'react';

import { Box, Button, ButtonBase, Container, IconButton, Typography } from '@material-ui/core';
import { Facebook, Instagram, MailOutline } from '@material-ui/icons';

import { useMuiBreakpoints } from '../../../misc/hooks/useMuiBreakpoints';
import { BrandStamp } from '../../reusable/BrandStamp';
import { SectionTitle } from '../../reusable/SectionTitle';
import { Orbsquare } from '../../reusable/Orbsquare';
import { Sections } from '../../../config/sections';

import { useStyles } from './Footer.styles';

const HEADER_TEXT = 'Uwielbiamy pytania, komentarze, opinie.';
const SUB_TEXT = 'Zwłaszcza o Kombuchy. Pisz do nas śmiało i pytaj o wszystko.';
const COPYRIGHT_TEXT = '© 2020 orb2 All Rights Reserved';

export const Footer = () => {
  const classes = useStyles();
  const { isMobile } = useMuiBreakpoints();

  return (
    <Container maxWidth={'md'} component={'footer'} id={Sections.contact}>
      <SectionTitle title={HEADER_TEXT} subtitle={SUB_TEXT} />
      <Box display={'flex'} textAlign={isMobile ? 'center' : 'left'} flexDirection={isMobile ? 'column' : 'row'}>
        <Box flex={1}>
          <Typography variant={'h5'} children={'Kombucha Sp. z o.o.'} />
          <Typography children={'Ludwika Narbutta 55/57/41'} />
          <Typography children={'02-529 Warszawa'} />
          <Typography children={'NIP: 5213868305'} />
          <Typography children={'REGON: 383580555'} />
          <Typography children={'KRS: 0000789567'} />
          <IconButton edge={'start'} children={<MailOutline />} href={'mailto:hello@majesteakombucha.pl'} />
          <IconButton children={<Facebook />} href={'https://www.facebook.com/MAJESTEAkombucha/'} target={'_blank'} />
          <IconButton children={<Instagram />} href={'https://www.instagram.com/majesteakombucha/'} target={'_blank'} />
        </Box>

        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flex={1} padding={4}>
          <BrandStamp width={128} />
        </Box>

        <Box flex={1} textAlign={isMobile ? 'center' : 'right'}>
          <Typography variant={'h5'} children={'Sklep / zamówienia'} />
          <Button
            className={classes.button}
            size={'large'}
            href={'mailto:sklep@majesteakombucha.pl'}
            endIcon={<MailOutline />}
            children={'sklep@majesteakombucha.pl'}
          />

          <Typography variant={'h5'} children={'Współpraca'} />
          <Button
            className={classes.button}
            size={'large'}
            endIcon={<MailOutline />}
            href={'mailto:hello@majesteakombucha.pl'}
            children={'hello@majesteakombucha.pl'}
          />
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} marginTop={4}>
        <ButtonBase href={'https://orb2.space'} target={'_blank'}>
          <Orbsquare />
        </ButtonBase>
        <Typography color={'textSecondary'} variant={'overline'} align={'center'}>
          {COPYRIGHT_TEXT}
        </Typography>
      </Box>
    </Container>
  );
};

export default Footer;
