import React from 'react';

import { useRecoilValue } from 'recoil';
import { Box } from '@material-ui/core';

import KombuchaCard from '../KombuchaCard';
import { getKombuchas } from '../../state/kombuchas/selectors/getKombuchas';
import { useMuiBreakpoints } from '../../misc/hooks/useMuiBreakpoints';
import { SectionTitle } from '../reusable/SectionTitle';
import { Sections } from '../../config/sections';
import { i18n } from '../../lang/pl';

import { useStyles } from './Kombuchas.styles';

export const Kombuchas = () => {
  const { isMobile } = useMuiBreakpoints();
  const classes = useStyles();
  const kombuchas = useRecoilValue(getKombuchas);

  const kombuchaList = kombuchas.map((kombucha) => <KombuchaCard kombucha={kombucha} key={kombucha.id} />);

  return (
    <Box id={Sections.buy}>
      <SectionTitle title={i18n.kombuchas.flavs} subtitle={i18n.kombuchas.find} />
      <Box
        className={classes.root}
        component={'ul'}
        flexDirection={isMobile ? 'column' : 'row'}
        children={kombuchaList}
      />
    </Box>
  );
};
