import React from 'react';

import cn from 'classnames';
import { animated as a, config, useSpring } from 'react-spring';
import { Container, useScrollTrigger } from '@material-ui/core';
import { Sections } from '../../config/sections';

import { useStyles } from './Header.styles';

import arc from './../../misc/images/title_arc.svg';
import lady from './../../misc/images/lady_figure.svg';

export const Header = () => {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
  });
  const titleAnimation = useSpring({
    from: { transform: 'translate3d(0, -100%, 0)' },
    to: { transform: 'translate3d(0, 0%, 0)' },
    delay: 500,
    config: config.molasses,
  });

  const ladyAnimation = useSpring({
    opacity: trigger ? 0 : 1,
    config: config.molasses,
  });

  return (
    <Container component={'header'} className={classes.root} id={Sections.top} disableGutters>
      <a.img className={cn(classes.image, classes.arc)} src={arc} alt={'Majestea'} style={titleAnimation} />
      <a.img className={cn(classes.image, classes.fixed)} src={lady} alt={'Kombucha'} style={ladyAnimation} />
    </Container>
  );
};
