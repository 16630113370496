class StrapiService {
  public static getDataUri(slug: string) {
    return `${process.env.REACT_APP_API_URL}/${slug}`;
  }

  public static getDataUriId(slug: string, id: string) {
    return `${process.env.REACT_APP_API_URL}/${slug}/${id}`;
  }

  public static getCouponUri(couponCode: string, email: string) {
    return `${process.env.REACT_APP_API_URL}/coupons/${couponCode}/${email}`;
  }

  protected static unNullable(nan?: number | null) {
    if (!nan) {
      return 0;
    }

    return nan;
  }
}

export default StrapiService;
