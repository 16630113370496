import React from 'react';

import { Typography } from '@material-ui/core';

import { ISectionTitleProps } from './SectionTitle';

import { useStyles } from './SectionTitle.styles';
import cn from 'classnames';

export const SectionTitle = ({ align, title, subtitle }: ISectionTitleProps) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        align={align}
        className={classes.width}
        color={'primary'}
        variant={'h3'}
        children={title}
        gutterBottom
      />
      <Typography
        align={align}
        className={cn(classes.bottomSpacing, classes.width)}
        variant={'subtitle1'}
        children={subtitle}
      />
    </>
  );
};
