import React from 'react';
import { render } from 'react-dom';

import './misc/styles/global.module.scss';

import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from '@material-ui/core/styles';

import { App } from './components/App';
import { mui } from './config/mui';
import { ErrorPage } from './pages/Error';
import { ErrorBoundary } from './components/ErrorBoundary';

const error = <ErrorPage />;

render(
  <RecoilRoot>
    <ErrorBoundary fallback={error}>
      <Router>
        <ThemeProvider theme={mui.theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Router>
    </ErrorBoundary>
  </RecoilRoot>,
  document.getElementById('root')
);
