import React from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

import { useShipping } from '../../state/cart/hooks/useShipping';
import { IShippingProps } from './Shipping';
import { Texts } from '../../config/texts';
import { i18n } from '../../lang/pl';

import { useStyles } from './Shipping.styles';

export const Shipping = ({ totalCount }: IShippingProps) => {
  const classes = useStyles();
  const { handleChange, shippingOptions, shippingOptionId } = useShipping(totalCount);

  const options = shippingOptions.map((option) => (
    <FormControlLabel
      key={option.id}
      value={option.id}
      control={<Radio />}
      label={`${option.serviceName} ∙ ${option.price}\xA0${Texts.currency}${
        option.serviceDescription ? Texts.separator + option.serviceDescription : ''
      }`}
    />
  ));

  return (
    <FormControl
      className={classes.root}
      component={'fieldset'}
      variant={'standard'}
      margin={'dense'}
      fullWidth
      required
    >
      <FormLabel component={'legend'} children={i18n.shipping.text} />
      <RadioGroup
        aria-label={'shipping'}
        onChange={handleChange}
        children={options}
        aria-required={'true'}
        value={shippingOptionId}
      />
    </FormControl>
  );
};
