import { selector } from 'recoil';

import { KombuchasApi } from '../../../services/$kombuchas-api';
import { RecoilKeys } from '../../../config/recoilKeys';
import { IKombuchaResponse } from '../../../models/Kombuchas';

export const getKombuchas = selector<IKombuchaResponse[]>({
  get: async ({ get }) => {
    const response = await KombuchasApi.getKombuchas();

    return response || [];
  },
  key: RecoilKeys.getKombuchas,
});
