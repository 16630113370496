import axios, { CancelTokenSource } from 'axios';

import catchHttpError from '../../misc/helpers/api/catchHttpError';
import getData from '../../misc/helpers/api/getData';
import withCacheHeader from '../../misc/helpers/api/withCacheHeader';
import StrapiService from '../../misc/classes/StrapiService';
import { ICouponResponse } from '../../models/Coupon';

export class CouponApi extends StrapiService {
  private static cancelTokenCoupon?: CancelTokenSource;

  public static cancelCoupon() {
    if (CouponApi.cancelTokenCoupon) {
      CouponApi.cancelTokenCoupon.cancel();
      CouponApi.cancelTokenCoupon = undefined;
    }
  }

  public static getCoupon(couponCode: string, email: string): Promise<ICouponResponse> {
    return new Promise<ICouponResponse>((resolve, reject) => {
      CouponApi.cancelTokenCoupon = axios.CancelToken.source();

      axios
        .get(
          CouponApi.getCouponUri(couponCode, email),
          withCacheHeader({
            cancelToken: CouponApi.cancelTokenCoupon.token,
          })
        )
        .then(getData)
        .then(resolve)
        .catch((error) => {
          reject(catchHttpError(error));
        });
    });
  }
}
