import React, { Suspense } from 'react';

import { MainContainer } from '../../components/layout/MainContainer';
import { Cart } from '../../components/Cart';
import { SimpleLoading } from '../../components/reusable/SimpleLoading';

const loading = <SimpleLoading />;

export const CartPage = () => (
  <MainContainer>
    <Suspense fallback={loading}>
      <Cart />
    </Suspense>
  </MainContainer>
);
