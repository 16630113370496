/**
 * Function checks if value is in range.
 *
 * @param { number } value - number to check
 * @param { number } min - lower limit
 * @param { number | undefined } max - upper limit
 *
 * @return { boolean } checks true if number is in range
 */
export const isNumberInRange = (value: number, min: number, max: number) => value >= min && value <= max;
