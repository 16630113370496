import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export const useMuiBreakpoints = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = !isMobile && !isDesktop;

  return {
    isDesktop,
    isMobile,
    isTablet,
    noDesktop: !isDesktop,
    noMobile: !isMobile,
    noTablet: !isTablet,
  };
};
