import React from 'react';

import { IOrbsquareProps } from './Orbsquare';

export const Orbsquare = ({ height = 32, invert }: IOrbsquareProps) => {
  const fill = invert ? '#fff' : '#000';

  return (
    <svg
      height={height}
      xmlns={'http://www.w3.org/2000/svg'}
      x={0}
      y={0}
      viewBox={'0 0 211.38 81'}
      xmlSpace={'preserve'}
    >
      <g>
        <path
          fill={fill}
          d={
            'M0 54.14c0-16.42 11.16-26.79 27.15-26.79S54.3 37.72 54.3 54.14C54.29 70.63 43.13 81 27.15 81 11.09 81 0 70.63 0 54.14zm37.44 0c0-9.14-4.25-12.96-10.3-12.96-6.05 0-10.3 3.82-10.3 12.96 0 9.22 4.25 13.03 10.3 13.03 6.05 0 10.3-3.81 10.3-13.03zM104.84 79.77H88.2c-.65-1.37-.94-2.95-1.01-5.18l-.22-5.11c-.22-4.9-1.73-6.41-6.55-6.41H74.8v16.71H58.54V28.51h25.27c15.41 0 19.73 7.06 19.73 15.05 0 6.12-2.59 10.23-7.7 12.24 4.75 1.8 6.77 5.33 7.2 11.23l.58 7.56c.21 2.66.42 3.89 1.22 5.18zM86.91 46.15c0-3.53-1.94-5.11-5.62-5.11h-6.48v10.22h6.48c3.67 0 5.62-1.58 5.62-5.11zM155.74 64.65c0 9.07-5.83 15.12-19.3 15.12h-27.87V28.51h27.36c13.32 0 17.71 6.48 17.71 13.03 0 5.04-2.59 8.86-6.7 10.73 5.49 1.94 8.8 7.27 8.8 12.38zm-30.89-23.62v7.2h7.99c2.66 0 4.03-1.3 4.03-3.6s-1.37-3.6-4.03-3.6h-7.99zm14.04 22.61c0-2.3-1.37-3.6-4.03-3.6h-10.01v7.2h10.01c2.66 0 4.03-1.29 4.03-3.6z'
          }
        />
        <path
          d={
            'M182.88 0l-28.5 28.5 28.5 28.51 28.5-28.51L182.88 0zm-12.54 40.87v-3.05c0-5.86 2.77-8.71 8.79-10.97l2.93-1.11c2.81-1.07 3.68-1.9 3.68-3.45 0-1.82-1.31-2.73-3.57-2.73-2.38 0-3.41 1.07-3.57 3.49h-8.36c.4-7.09 4.83-11.01 12.68-11.01 8.12 0 11.96 4.2 11.96 9.51 0 5.11-3.56 7.68-7.76 9.23l-4.04 1.51c-.99.36-1.94.75-2.57 1.35h14.54v7.25h-24.71z'
          }
          fill={'#eb1b24'}
        />
      </g>
    </svg>
  );
};
