import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { ISimpleGridStyleProps } from './SimpleGrid';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      paddingTop: ({ spacing = 0 }: ISimpleGridStyleProps) => theme.spacing(spacing),
    },
    item: {
      marginBottom: ({ spacing = 0 }: ISimpleGridStyleProps) => theme.spacing(spacing),
    },
  })
);
