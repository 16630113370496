import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { cartItems } from '../atoms/cartItems';
import LocalStorage from '../../../misc/classes/LocalStorage';
import { RecoilKeys } from '../../../config/recoilKeys';
import { ICartItem } from '../../../models/Cart';

export const useCartFromStorage = () => {
  const setCart = useSetRecoilState(cartItems);

  useEffect(() => {
    const storageCart = LocalStorage.getItem<ICartItem[]>(RecoilKeys.cartItems);

    if (storageCart) {
      setCart(storageCart);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
