import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useOrderStatus } from './useOrderStatus';
import { SimpleLoading } from '../reusable/SimpleLoading';
import { BrandStamp } from '../reusable/BrandStamp';
import { i18n } from '../../lang/pl';

const loading = <SimpleLoading />;
export const OrderStatus = () => {
  const { data, errorStatus, isError } = useOrderStatus();
  const errorText = `${i18n.errors[501].text} (error: ${errorStatus})`;
  const confirmTitle = `${i18n.orderStatus.confirmTitle} ${data?.id}`;

  const element = (
    <>
      <Box textAlign={'center'} marginBottom={1}>
        <BrandStamp width={128} />
      </Box>
      <Typography
        align={'center'}
        color={'primary'}
        variant={'h5'}
        children={isError ? i18n.errors[501].title : confirmTitle}
        gutterBottom
      />
      <Typography align={'center'} variant={'body1'} children={isError ? errorText : i18n.orderStatus.confirmText} />
    </>
  );

  return <Box children={data || isError ? element : loading} />;
};
