import React from 'react';

import { Router } from '../../router';
import { CartIcon } from '../reusable/CartIcon';
import { useCartFromStorage } from '../../state/cart/hooks/useCartFromStorage';
import BuyNow from '../BuyNow';

export const App = () => {
  useCartFromStorage();

  return (
    <>
      <Router />
      <BuyNow />
      <CartIcon float />
    </>
  );
};
