import React from 'react';

import { Box } from '@material-ui/core';

import { useMuiBreakpoints } from '../../../misc/hooks/useMuiBreakpoints';
import { IDoubleColumnProps } from './DoubleColumn';

import { useStyles } from './DoubleColumn.styles';

export const DoubleColumn = ({ children, leftColSize = 5, spacing }: IDoubleColumnProps) => {
  const { noMobile } = useMuiBreakpoints();
  const classes = useStyles({
    spacing,
    noMobile,
  });

  const sizes = () => ({
    left: `${leftColSize * 10}%`,
    right: `${(10 - leftColSize) * 10}%`,
  });

  return (
    <Box className={classes.root}>
      <Box width={noMobile ? sizes().left : '100%'} className={classes.leftColumn} children={children[0]} />
      <Box width={noMobile ? sizes().right : '100%'} className={classes.rightColumn} children={children[1]} />
    </Box>
  );
};
