import { atom } from 'recoil';

import { RecoilKeys } from '../../../config/recoilKeys';
import { ICartItem } from '../../../models/Cart';

export const initialState: ICartItem[] = [];

export const cartItems = atom<ICartItem[]>({
  default: initialState,
  key: RecoilKeys.cartItems,
});
