import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface IStyleProps {
  size: 'medium' | 'large';
}

enum Spacing {
  'medium' = 2.35,
  'large' = 2,
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      backgroundColor: theme.palette.background.paper,
      position: 'fixed',
      bottom: ({ size }: IStyleProps) => theme.spacing(Spacing[size]),
      right: ({ size }: IStyleProps) => theme.spacing(Spacing[size]),
    },
  })
);
