import { selector } from 'recoil';

import { RecoilKeys } from '../../../config/recoilKeys';
import { couponCode } from '../atoms/couponCode';
import { CouponApi } from '../../../services/$coupon-api';
import { ICoupon } from '../../../models/Coupon';

export const getCoupon = selector<ICoupon | null>({
  get: async ({ get }) => {
    const coupon = get(couponCode);

    if (coupon) {
      const { amount, type, isValid } = await CouponApi.getCoupon(coupon.coupon, coupon.email);

      return { amount, type, isValid };
    }

    return null;
  },
  key: RecoilKeys.getCoupon,
});
