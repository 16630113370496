import React from 'react';

import { Button, FormControl, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { i18n } from '../../lang/pl';
import { TextInput } from '../reusable/TextInput';
import { useCoupon } from './useCoupon';
import { useMuiBreakpoints } from '../../misc/hooks/useMuiBreakpoints';

import { useStyles } from './Coupon.styles';

export const Coupon = () => {
  const { control, defaultValues, errors, handleDelete, handleToggle, isError, onSubmit, showForm, validationRules } =
    useCoupon();
  const { isMobile } = useMuiBreakpoints();
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.root}>
      <Button onClick={handleToggle} variant={'text'} children={i18n.coupon.gotCoupon + (showForm ? ' △' : ' ▽')} />
      {showForm && (
        <form onSubmit={onSubmit}>
          <FormControl
            className={classes.container}
            component={'fieldset'}
            variant={'standard'}
            margin={'dense'}
            fullWidth
          >
            <TextInput
              error={isError || !!errors.coupon}
              label={errors.coupon?.message || i18n.forms.coupon}
              control={control}
              defaultValue={defaultValues.coupon}
              name={'coupon'}
              rules={validationRules.coupon}
            />
            <TextInput
              error={isError || !!errors.email}
              label={errors.email?.message || i18n.forms.email}
              control={control}
              defaultValue={defaultValues.email}
              name={'email'}
              rules={validationRules.email}
            />
            <Button color={'primary'} type={'submit'} variant={'outlined'} size={'small'} children={i18n.coupon.add} />
          </FormControl>
        </form>
      )}
      <Snackbar open={isError} autoHideDuration={3000} onClose={handleDelete}>
        <MuiAlert severity={'error'} elevation={6} variant={'filled'} children={i18n.coupon.error} />
      </Snackbar>
    </div>
  );
};
