export enum RecoilKeys {
  cartItems = 'cartMajestea',
  couponCode = 'couponCode',
  getCartItems = 'getCartItems',
  getCoupon = 'getCoupon',
  getKombuchas = 'getKombuchas',
  isCheckout = 'isCheckout',
  isLoadingCart = 'isLoadingCart',
  shipping = 'shipping',
  shippingCost = 'shippingCost',
}
