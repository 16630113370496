import { ICoupon } from '../../models/Coupon';

export const applyCoupon = (price: number, coupon: ICoupon | null) => {
  if (coupon) {
    if (coupon.type === 'amount') {
      return {
        price: price - coupon.amount,
        coupon: `-${coupon.amount} PLN`,
      };
    }

    if (coupon.type === 'percentage') {
      const couponValue = price * (coupon.amount / 100);
      return {
        price: price - couponValue,
        coupon: `-${couponValue} PLN`,
      };
    }
  }

  return { price, coupon: '' };
};
