import React, { useEffect } from 'react';

import { Route, Switch, useLocation } from 'react-router-dom';

import { Routes } from './routes';
import { Page } from '../components/layout/Page';

import { MainPage } from '../pages/Main';
import { CartPage } from '../pages/Cart';
import { ErrorPage } from '../pages/Error';
import { OrderStatusPage } from '../pages/OrderStatus';
import { i18n } from '../lang/pl';

export const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== Routes.index) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  return (
    <Switch>
      <Route exact={true} path={Routes.index} component={Page(MainPage)} />
      <Route exact={true} path={Routes.cart} component={Page(CartPage)} />
      <Route exact={true} path={Routes.orderStatus} component={Page(OrderStatusPage)} />
      <Route exact={true} path={Routes.error} component={Page(ErrorPage)} />
      <Route component={Page(() => ErrorPage(i18n.errors['404']))} />
    </Switch>
  );
};
