import React from 'react';

import { Typography } from '@material-ui/core';
import { Sections } from '../../config/sections';
import { i18n } from '../../lang/pl';

export const About = () => (
  <>
    <Typography id={Sections.about} color={'primary'} variant={'h2'} children={i18n.about.title} gutterBottom />
    <Typography variant={'body1'} children={i18n.about.text} />
  </>
);
