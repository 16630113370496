import React, { useEffect, useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { ShoppingCartOutlined } from '@material-ui/icons';
import { Badge, Fab, IconButton } from '@material-ui/core';
import { Routes } from '../../../router/routes';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { cartItems } from '../../../state/cart/atoms/cartItems';
import { ICartIconProps } from './CartIcon';
import { isCheckout } from '../../../state/cart/atoms/isCheckout';

import { useStyles } from './CartIcon.styles';

export const CartIcon = ({ float }: ICartIconProps) => {
  const [size, setSize] = useState<'medium' | 'large'>('large');
  const { pathname } = useLocation();
  const { push } = useHistory();
  const classes = useStyles({ size });
  const setCheckout = useSetRecoilState(isCheckout);
  const items = useRecoilValue(cartItems);
  const itemsLength: number = items.reduce((a, c) => a + c.count, 0);

  const handleClick = () => {
    setCheckout(false);
    push(Routes.cart);
  };

  useEffect(() => {
    if (itemsLength > 0) {
      setSize('medium');
      setTimeout(() => setSize('large'), 50);
      setTimeout(() => setSize('medium'), 150);
      setTimeout(() => setSize('large'), 250);
    }
  }, [itemsLength]);

  const icon = (
    <Badge badgeContent={itemsLength} color={'secondary'}>
      <ShoppingCartOutlined color={'primary'} fontSize={'large'} />
    </Badge>
  );

  const button = <IconButton edge={'end'} size={'small'} children={icon} onClick={handleClick} />;

  const floating =
    itemsLength && pathname !== Routes.cart ? (
      <Fab size={size} className={classes.fab} children={icon} onClick={handleClick} />
    ) : null;

  return float ? floating : button;
};
