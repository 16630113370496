import { useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

import { OrderApi } from '../../services/$order-api';
import { IOrderResponse } from '../../models/Order';
import LocalStorage from '../../misc/classes/LocalStorage';
import { RecoilKeys } from '../../config/recoilKeys';
import { useSetRecoilState } from 'recoil';
import { cartItems, initialState } from '../../state/cart/atoms/cartItems';

export const useOrderStatus = () => {
  const [data, setData] = useState<IOrderResponse | null>(null);
  const setCart = useSetRecoilState(cartItems);
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const url = OrderApi.getDataUriId('orders', id);
  const error = new URLSearchParams(location.search).get('error');

  useEffect(() => {
    if (id && !Boolean(error)) {
      const subscription = ajax
        .getJSON<IOrderResponse>(url)
        .pipe(
          map((response) => {
            if (Boolean(response.id)) {
              LocalStorage.deleteItem(RecoilKeys.cartItems);
              setCart(initialState);
            }

            return response;
          }),
          catchError((error) => [])
        )
        .subscribe(setData);

      return () => subscription.unsubscribe();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, errorStatus: error, isError: Boolean(error) };
};
