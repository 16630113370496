import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { mui } from '../../config/mui';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      width: `calc(100% + ${theme.spacing(mui.vars.kombuchasCardSpacing)}px)`,
      transform: `translateX(-${theme.spacing(mui.vars.kombuchasCardSpacing / 2)}px)`,
      marginBottom: `-${theme.spacing(mui.vars.kombuchasCardSpacing / 2)}px`,
      marginTop: `-${theme.spacing(mui.vars.kombuchasCardSpacing / 2)}px`,
      padding: 0,
    },
  })
);
