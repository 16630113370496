import { useRecoilState, useSetRecoilState } from 'recoil';

import { cartItems } from '../atoms/cartItems';
import LocalStorage from '../../../misc/classes/LocalStorage';
import { RecoilKeys } from '../../../config/recoilKeys';
import { Values } from '../../../config/values';
import { ICartItem } from '../../../models/Cart';
import { isCheckout } from '../atoms/isCheckout';

export const useCartItems = () => {
  const [items, setItems] = useRecoilState(cartItems);
  const setCheckout = useSetRecoilState(isCheckout);

  const saveCartToStorage = (items: ICartItem[]) => {
    LocalStorage.setItem(RecoilKeys.cartItems, items, Values.oneDayMs);
  };

  const addToCart = (id: string) => {
    setCheckout(false);
    const alreadyInCart = items.some((item) => item.id === id);

    if (alreadyInCart) {
      const incremented = items.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            count: item.count + 1,
          };
        }

        return item;
      });

      saveCartToStorage(incremented);
      setItems(incremented);
    } else {
      const added = [
        ...items,
        {
          id,
          count: 1,
        },
      ];

      saveCartToStorage(added);
      setItems(added);
    }
  };

  const decrementCart = (id: string) => {
    const decremented = items.map((item) => {
      if (item.id === id && item.count > 1) {
        return {
          id: item.id,
          count: item.count - 1,
        };
      }

      return item;
    });

    saveCartToStorage(decremented);
    return setItems(decremented);
  };

  const removeFromCart = (id: string) => {
    const removed = items.filter((item) => item.id !== id);

    LocalStorage.deleteItem(RecoilKeys.cartItems);
    setItems(removed);
  };

  return {
    addToCart,
    decrementCart,
    removeFromCart,
  };
};
