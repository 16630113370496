import { selector } from 'recoil';

import { RecoilKeys } from '../../../config/recoilKeys';
import { cartItems } from '../atoms/cartItems';
import { getKombuchas } from '../../kombuchas/selectors/getKombuchas';
import { ICartItemExtended } from '../../../models/Cart';
import { roundNumber } from '../../../misc/helpers/roundNumber';

export const getCartItems = selector<ICartItemExtended[]>({
  get: ({ get }) => {
    const items = get(cartItems);
    const kombuchas = get(getKombuchas);

    return items.map((item) => {
      const match = kombuchas.find((one) => one.id === item.id);
      const price = (match?.price ?? 0) * item.count;

      return {
        ...item,
        flavour: match?.flavour ?? '',
        price: roundNumber(price),
      };
    });
  },
  key: RecoilKeys.getCartItems,
});
