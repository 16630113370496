import { useMemo } from 'react';

import { IValidator } from '../../models/Forms';
import { regExp } from '../../config/regExp';

export const useStreetNumberValidator = (requiredMsg: string, correctMsg: string): IValidator =>
  useMemo(
    () => ({
      required: {
        value: true,
        message: requiredMsg,
      },
      pattern: {
        value: regExp.streetNumber,
        message: correctMsg,
      },
    }),
    [requiredMsg, correctMsg]
  );
