import { useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { ICouponFormValues } from '../../models/Coupon';
import { couponCode } from '../../state/coupon/atoms/couponCode';
import { getCoupon } from '../../state/coupon/selectors/getCoupon';
import { validators } from '../../misc/validators';
import { i18n } from '../../lang/pl';

export const useCoupon = () => {
  const [showForm, toggleForm] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const setCoupon = useSetRecoilState(couponCode);
  const coupon = useRecoilValueLoadable(getCoupon);
  const isError = coupon.state === 'hasValue' && coupon.contents?.type === 'none';

  const handleToggle = () => toggleForm(!showForm);

  const handleDelete = () => setCoupon(null);

  const defaultValues: ICouponFormValues = {
    coupon: '',
    email: '',
  };

  const validationRules = {
    coupon: validators.couponCode(i18n.validators.couponCode),
    email: validators.email(i18n.validators.email, i18n.validators.emailCorrect),
  };

  const onSubmit: SubmitHandler<ICouponFormValues> = (data) => {
    setCoupon(data);
  };

  return {
    control,
    defaultValues,
    errors,
    handleDelete,
    handleToggle,
    isError,
    onSubmit: handleSubmit(onSubmit),
    showForm,
    validationRules,
  };
};
