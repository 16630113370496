class LocalStorage {
  /**
   * Function setting localStorage item with optional expiration time in milliseconds.
   *
   * @param storeKey - string key under which to store the data.
   * @param data - data object to be stored.
   * @param ttl - optional expiration time in milliseconds:
   *
   * 60000 - 1 minute
   * 3600000 - 1 hour
   */
  public setItem(storeKey: string, data: any, ttl?: number) {
    const now = new Date();
    const expiration = ttl ? now.getTime() + ttl : 0;

    try {
      window.localStorage.setItem(
        storeKey,
        JSON.stringify({
          data,
          expiration,
        })
      );
    } catch (noWindow) {}
  }

  /**
   * Function getting items from local storage for specific key and type.
   *
   * @param {string} storeKey
   *
   * @return {T | null}
   */
  public getItem<T>(storeKey: string): T | null {
    try {
      const storage = window.localStorage.getItem(storeKey);

      if (!storage) {
        return null;
      }

      const storageItem = JSON.parse(storage);

      if (storageItem.expiration) {
        const now = new Date();

        if (now.getTime() > storageItem.expiration) {
          window.localStorage.removeItem(storeKey);

          return null;
        }

        delete storageItem.expiration;
      }

      return storageItem.data;
    } catch (error) {
      return null;
    }
  }

  /**
   * Function deletes items stored in local storage under given key.
   *
   * @param {string} storeKey
   */
  public deleteItem(storeKey: string) {
    try {
      window.localStorage.removeItem(storeKey);
    } catch (noWindow) {}
  }
}

export default new LocalStorage();
