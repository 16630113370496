import axios from 'axios';

import getData from '../../misc/helpers/api/getData';
import StrapiService from '../../misc/classes/StrapiService';
import withCacheHeader from '../../misc/helpers/api/withCacheHeader';
import { IOrderRequestBody, IOrderSuccessResponse } from '../../models/Order';

export class OrderApi extends StrapiService {
  public static postOrder(body: IOrderRequestBody): Promise<IOrderSuccessResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post(OrderApi.getDataUri('orders'), body, withCacheHeader())
        .then(getData)
        .then(resolve)
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }
}
