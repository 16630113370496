import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    container: {
      flexDirection: (props: { isMobile: boolean }) => (props.isMobile ? 'column' : 'row'),
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  })
);
