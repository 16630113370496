import React from 'react';

import { Container } from '@material-ui/core';
import { IMainContainerProps } from './MainContainer';

import { useStyles } from './MainContainer.styles';

export const MainContainer = ({ children }: IMainContainerProps) => {
  const classes = useStyles();

  return <Container className={classes.root} component={'main'} maxWidth={'md'} children={children} />;
};
