import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { mui } from '../../config/mui';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      flex: 1,
      minWidth: `calc(50% - ${theme.spacing(mui.vars.kombuchasCardSpacing)}px)`,
      margin: theme.spacing(mui.vars.kombuchasCardSpacing / 2),
    },
    icon: {
      padding: 6,
    },
    image: {
      height: '100%',
      maxHeight: 200,
      objectFit: 'contain',
    },
    imageContainer: {
      flex: 1,
    },
    content: {
      display: 'flex',
      flex: 2,
      flexFlow: 'column',
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
    actionArea: {
      flex: 1,
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(4),
      padding: 0,
    },
  })
);
