import { ChangeEvent, useEffect, useState } from 'react';

import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { IShippingOption, IShippingResponse } from '../../../models/Shipping';
import StrapiService from '../../../misc/classes/StrapiService';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { shipping } from '../atoms/shipping';
import { isNumberInRange } from '../../../misc/helpers/isNumberInRange';
import { ICalculatedShippingOption } from '../../../models/Cart';
import { shippingCost } from '../atoms/shippingCost';

export const useShipping = (totalCount: number) => {
  const [data, setData] = useState<IShippingOption[]>([]);
  const [calculatedOptions, recalculateOptions] = useState<ICalculatedShippingOption[]>([]);
  const [shippingOptionId, setShippingOption] = useRecoilState(shipping);
  const setShippingCost = useSetRecoilState(shippingCost);
  const url = StrapiService.getDataUri('shipping');

  useEffect(() => {
    const opt = data.map((option) => {
      const cost = option.shippingCosts.find((entity) => isNumberInRange(totalCount, entity.minItems, entity.maxItems));

      return {
        id: cost?.id || '',
        price: cost?.price || 0,
        serviceName: option.serviceName,
        serviceDescription: option.serviceDescription,
      };
    });

    recalculateOptions(opt);

    const notValid = !opt.some(({ id }) => id === shippingOptionId);

    if (notValid) {
      setShippingCost(0);
      setShippingOption('');
    }
  }, [totalCount, data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShippingCost(calculatedOptions.find((opt) => opt.id === event.target.value)?.price ?? 0);
    setShippingOption(event.target.value);
  };

  useEffect(() => {
    const subscription = ajax
      .getJSON<IShippingResponse>(url)
      .pipe(
        map((response) => response.shippingOptions),
        catchError((error) => [])
      )
      .subscribe(setData);

    return () => subscription.unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    shippingOptionId,
    handleChange,
    shippingOptions: calculatedOptions,
  };
};
