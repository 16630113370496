import React from 'react';

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@material-ui/core';
import { AddShoppingCartOutlined, InfoOutlined } from '@material-ui/icons';

import { IKombuchaCardProps } from './KombuchaCard.types';

import { useStyles } from './KombuchaCard.styles';
import { Texts } from '../../config/texts';
import { useCartItems } from '../../state/cart/hooks/useCartItems';

const KombuchaCard = ({ kombucha }: IKombuchaCardProps) => {
  const classes = useStyles();
  const { addToCart } = useCartItems();

  // const isOnSale = kombucha.on_sale && kombucha.quantity && kombucha.quantity > 0;

  const addItemToCart = () => addToCart(kombucha.id);

  return (
    <Card
      component={'li'}
      className={classes.root}
      elevation={0}
      itemProp={'itemListElement'}
      itemScope
      itemType={'https://schema.org/Product'}
    >
      <CardActionArea className={classes.imageContainer} onClick={addItemToCart}>
        <CardMedia
          className={classes.image}
          component={'img'}
          image={kombucha.image.url}
          title={kombucha.flavour}
          height={100}
          itemProp={'image'}
        />
      </CardActionArea>
      <CardContent className={classes.content}>
        <Typography color={'primary'} variant={'h4'} children={kombucha.flavour} gutterBottom itemProp={'name'} />
        <Typography color={'textSecondary'} variant={'body2'} children={kombucha.description} />
        <Typography variant={'overline'} color={'primary'} children={'250ml'} />
        <CardActions className={classes.actionArea} itemProp={'offers'} itemScope itemType={'https://schema.org/Offer'}>
          <IconButton
            className={classes.icon}
            children={<InfoOutlined />}
            color={'primary'}
            onClick={addItemToCart}
            disabled
          />
          <Button
            endIcon={<AddShoppingCartOutlined />}
            color={'primary'}
            variant={'contained'}
            disableElevation
            children={`${kombucha.price} ${Texts.currency}`}
            itemProp={'price'}
            onClick={addItemToCart}
          />
          <meta itemProp={'priceCurrency'} content={'PLN'} />
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default KombuchaCard;
