import React, { ComponentType, FC } from 'react';

import { RouteComponentProps } from 'react-router';
import { Box } from '@material-ui/core';

import { Navbar } from '../Navbar';
import { useStyles } from './Page.styles';
import { Footer } from '../Footer';

const PageLayout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Navbar />
      <Box className={classes.offset} />
      <Box component={'main'} children={children} />
      <Footer />
    </>
  );
};

export const Page = (page: ComponentType<any>, disableLayout?: boolean) => {
  if (disableLayout) {
    return page;
  }

  return (props: RouteComponentProps) => <PageLayout children={page} />;
};
