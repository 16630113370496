import HttpError from '../../classes/HttpError';

const catchHttpError = (error: any) => {
  if (error?.response?.status) {
    return new HttpError('Http Error', error.response.status);
  }

  return error;
};

export default catchHttpError;
