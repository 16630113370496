import { useCityValidator } from './city';
import { useCompanyNameValidator } from './companyName';
import { useCouponCodeValidator } from './couponCode';
import { useEmailValidator } from './email';
import { useFirstNameValidator } from './firstName';
import { useLastNameValidator } from './lastName';
import { useNipValidator } from './nip';
import { usePhoneValidator } from './phone';
import { useStreetNumberValidator } from './streetNumber';
import { useStreetValidator } from './street';
import { useTermsValidator } from './terms';
import { useZipValidator } from './zip';

export const validators = {
  city: useCityValidator,
  companyName: useCompanyNameValidator,
  couponCode: useCouponCodeValidator,
  email: useEmailValidator,
  firstName: useFirstNameValidator,
  lastName: useLastNameValidator,
  nip: useNipValidator,
  phone: usePhoneValidator,
  streetNumber: useStreetNumberValidator,
  street: useStreetValidator,
  terms: useTermsValidator,
  zip: useZipValidator,
};
