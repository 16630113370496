import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { getCartItems } from '../selectors/getCartItems';
import { Values } from '../../../config/values';
import { Texts } from '../../../config/texts';
import { shippingCost } from '../atoms/shippingCost';
import { shipping as shippingId } from '../atoms/shipping';
import { roundNumber } from '../../../misc/helpers/roundNumber';
import { getCoupon } from '../../coupon/selectors/getCoupon';
import { couponCode } from '../../coupon/atoms/couponCode';
import { applyCoupon } from '../../../misc/helpers/applyCoupon';

export const useCart = () => {
  const cart = useRecoilValue(getCartItems);
  const coupon = useRecoilValueLoadable(getCoupon);
  const totalCount = cart.reduce((a, c) => a + c.count, 0);
  const shippingPrice = useRecoilValue(shippingCost);
  const subTotal = roundNumber(cart.reduce((a, c) => a + c.price, 0));
  const couponPrice = applyCoupon(subTotal, coupon.state === 'hasValue' ? coupon.contents : null);
  const totalCost = shippingPrice + couponPrice.price;
  const vatValue = roundNumber(totalCost - totalCost / Values.vat);
  const disabled = !Boolean(useRecoilValue(shippingId));
  const setCouponCode = useSetRecoilState(couponCode);

  const deleteCoupon = () => setCouponCode(null);

  return {
    cart,
    coupon: couponPrice.coupon,
    disabled,
    deleteCoupon,
    shippingCost: `${shippingPrice}\xA0${Texts.currency}`,
    subTotal: `${subTotal}\xA0${Texts.currency}`,
    totalCost: `${totalCost}\xA0${Texts.currency}`,
    totalCount,
    vatValue: `${vatValue}\xA0${Texts.currency}`,
  };
};
