import { useMemo } from 'react';

import { IValidator } from '../../models/Forms';
import { regExp } from '../../config/regExp';

export const useNipValidator = (requiredMsg: string, correctMsg: string, show: boolean): IValidator =>
  useMemo(
    () => ({
      required: show ? requiredMsg : false,
      pattern: {
        value: regExp.nip,
        message: correctMsg,
      },
    }),
    [requiredMsg, correctMsg, show]
  );
