import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Switch,
  Typography,
} from '@material-ui/core';

import { useUserDataForm } from './useUserDataForm';
import { TextInput } from '../reusable/TextInput';
import { SimpleGrid } from '../layout/SimpleGrid';
import { DoubleColumn } from '../layout/DoubleColumn';
import { Values } from '../../config/values';

import payU from './../../misc/images/PayU.svg';
import { useStyles } from './UserData.styles';
import { ToggleSwitch } from '../reusable/ToggleSwitch';
import { i18n } from '../../lang/pl';

const GRID_SPACING = 2;

const loading = <CircularProgress size={Values.buttonProgressSize} />;

export const UserData = () => {
  const classes = useStyles();
  const {
    control,
    disabled,
    disableEmail,
    defaultValues,
    errors,
    showInvoice,
    toggleInvoice,
    isLoading,
    onSubmit,
    validationRules,
  } = useUserDataForm();

  const termsElement = (
    <>
      {i18n.userData.terms}
      <Link href={'/docs/MAJESTEA_terms_9_2020.pdf'} target={'_blank'} children={i18n.userData.termsLink} />
    </>
  );

  return (
    <form onSubmit={onSubmit}>
      <FormControl
        className={classes.root}
        component={'fieldset'}
        variant={'standard'}
        margin={'dense'}
        fullWidth
        required
      >
        <FormLabel component={'legend'} children={i18n.userData.dataForm} />
        <SimpleGrid component={'div'} spacing={GRID_SPACING}>
          <DoubleColumn spacing={GRID_SPACING}>
            <TextInput
              control={control}
              defaultValue={defaultValues.firstName}
              rules={validationRules.firstName}
              name={'firstName'}
              label={errors.firstName?.message || i18n.forms.firstName}
              error={!!errors.firstName}
            />
            <TextInput
              control={control}
              defaultValue={defaultValues.lastName}
              rules={validationRules.lastName}
              name={'lastName'}
              label={errors.lastName?.message || i18n.forms.lastName}
              error={!!errors.lastName}
            />
          </DoubleColumn>
          <DoubleColumn leftColSize={7} spacing={GRID_SPACING}>
            <TextInput
              control={control}
              defaultValue={defaultValues.street}
              rules={validationRules.street}
              name={'street'}
              label={errors.street?.message || i18n.forms.street}
              error={!!errors.street}
            />
            <TextInput
              control={control}
              defaultValue={defaultValues.streetNumber}
              rules={validationRules.streetNumber}
              name={'streetNumber'}
              label={errors.streetNumber?.message || i18n.forms.streetNumber}
              error={!!errors.streetNumber}
            />
          </DoubleColumn>
          <DoubleColumn leftColSize={3} spacing={GRID_SPACING}>
            <TextInput
              control={control}
              defaultValue={defaultValues.zip}
              rules={validationRules.zip}
              name={'zip'}
              label={errors.zip?.message || i18n.forms.zip}
              error={!!errors.zip}
            />
            <TextInput
              control={control}
              defaultValue={defaultValues.city}
              rules={validationRules.city}
              name={'city'}
              label={errors.city?.message || i18n.forms.city}
              error={!!errors.city}
            />
          </DoubleColumn>
          <DoubleColumn spacing={GRID_SPACING}>
            <TextInput
              control={control}
              defaultValue={defaultValues.email}
              rules={validationRules.email}
              name={'email'}
              label={errors.email?.message || i18n.forms.email}
              error={!!errors.email}
              disabled={disableEmail}
            />
            <TextInput
              control={control}
              defaultValue={defaultValues.phone}
              rules={validationRules.phone}
              name={'phone'}
              label={errors.phone?.message || i18n.forms.phone}
              error={!!errors.phone}
            />
          </DoubleColumn>
          <TextInput
            control={control}
            defaultValue={defaultValues.message}
            name={'message'}
            label={errors.message?.message || i18n.forms.message}
            error={!!errors.message}
          />
          <FormControlLabel
            control={<Switch color={'primary'} onChange={toggleInvoice} value={showInvoice} />}
            label={<Typography variant={'body2'} color={'textSecondary'} children={i18n.forms.invoice} />}
          />
          {showInvoice && (
            <DoubleColumn spacing={GRID_SPACING}>
              <TextInput
                control={control}
                defaultValue={defaultValues.companyName}
                rules={validationRules.companyName}
                name={'companyName'}
                label={errors.companyName?.message || i18n.forms.companyName}
                error={!!errors.companyName}
              />
              <TextInput
                control={control}
                defaultValue={defaultValues.nip}
                rules={validationRules.nip}
                name={'nip'}
                label={errors.nip?.message || i18n.forms.nip}
                error={!!errors.nip}
              />
            </DoubleColumn>
          )}
          <ToggleSwitch
            control={control}
            defaultValue={defaultValues.terms}
            rules={validationRules.terms}
            label={termsElement}
            name={'terms'}
            error={!!errors.terms}
          />
          {/* @TODO: add invoice */}
        </SimpleGrid>
      </FormControl>
      <Button
        className={classes.button}
        color={'primary'}
        type={'submit'}
        variant={'contained'}
        size={'large'}
        children={isLoading ? loading : i18n.userData.pay}
        disabled={disabled}
        fullWidth
        disableElevation
      />
      <Box className={classes.imageContainer}>
        <img className={classes.image} src={payU} alt={'PayU'} />
      </Box>
    </form>
  );
};
