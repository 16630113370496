import { IValidator } from '../../models/Forms';
import { useMemo } from 'react';

export const useCompanyNameValidator = (requiredMsg: string, show: boolean): IValidator =>
  useMemo(
    () => ({
      required: show ? requiredMsg : false,
    }),
    [requiredMsg, show]
  );
