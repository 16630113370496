import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
    },
  })
);
