import React from 'react';

import { TextField } from '@material-ui/core';
import { useController } from 'react-hook-form';

import { ITextInputProps } from './TextInput';

export const TextInput = ({ name, control, disabled, defaultValue, error, label, rules, ...rest }: ITextInputProps) => {
  const { field } = useController({
    name,
    rules,
    defaultValue,
    control,
  });

  return (
    <TextField {...rest} {...field} disabled={disabled} error={error} label={label} variant={'outlined'} fullWidth />
  );
};
