import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { BrandStamp } from '../../components/reusable/BrandStamp';
import { IErrorPageProps } from './Error';
import { i18n } from '../../lang/pl';

export const ErrorPage = ({ text, title }: IErrorPageProps) => (
  <Box alignItems={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'} minHeight={'80vh'}>
    <Box textAlign={'center'} marginBottom={1}>
      <BrandStamp width={128} />
    </Box>
    <Typography
      align={'center'}
      color={'primary'}
      variant={'h5'}
      children={title || i18n.errors['100'].title}
      gutterBottom
    />
    <Typography align={'center'} variant={'body1'} children={text || i18n.errors['100'].text} />
  </Box>
);
