import React, { useState } from 'react';

import { Link, Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Menu as MenuIcon, MenuOpen } from '@material-ui/icons';

import { mui } from '../../config/mui';
import { Sections } from '../../config/sections';
import { Routes } from '../../router/routes';
import { useNavigation } from '../../misc/hooks/useNavigation';

const menu = [
  {
    link: '#' + Sections.top,
    title: 'początek',
  },
  {
    link: '#' + Sections.about,
    title: 'o majestea',
  },
  {
    link: '#' + Sections.buy,
    title: 'zamów majestea',
  },
  {
    link: '#' + Sections.contact,
    title: 'kontakt',
  },
];

export const BurgerMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { navigate } = useNavigation();
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        edge={'start'}
        color={'inherit'}
        size={'small'}
        aria-label={mui.vars.menuId}
        aria-controls={mui.vars.menuId}
        aria-haspopup={'true'}
        onClick={handleClick}
      >
        {isOpen ? <MenuOpen color={'primary'} fontSize={'large'} /> : <MenuIcon color={'primary'} fontSize={'large'} />}
      </IconButton>
      <Menu id={mui.vars.menuId} anchorEl={anchorEl} keepMounted={false} open={isOpen} onClose={handleClose}>
        {menu.map((item) => (
          <MenuItem key={item.title}>
            <Link
              href={item.link}
              color={'inherit'}
              underline={'none'}
              children={item.title}
              onClick={(event) => navigate({ event, to: Routes.index, hash: item.link })}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
