import { useHistory, useLocation } from 'react-router';

import { scrollToHash } from '../helpers/scrollToHash';

interface INavigationProps {
  event: any;
  to: string;
  hash?: string;
}

/**
 * Returns a function to navigate using history push, with anchor link scroll implementation.
 *
 * @return {{navigate: ({event, to, hash}: INavigationProps) => void}}
 */
export const useNavigation = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const navigate = ({ event, to, hash }: INavigationProps) => {
    event.preventDefault();
    const thisRoute = pathname === to;

    if (!thisRoute) {
      push(to);
    }

    scrollToHash(Boolean(hash), hash, 250);
  };

  return {
    navigate,
  };
};
