import React from 'react';

import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import cn from 'classnames';

import { useMuiBreakpoints } from '../../misc/hooks/useMuiBreakpoints';

import { useStyles } from './Properties.styles';

import iconSugar from '../../misc/images/icons/icon_nosugar.svg';
import iconGluten from '../../misc/images/icons/icon_nogluten.svg';
import iconVegan from '../../misc/images/icons/icon_vegan.svg';
import iconRaw from '../../misc/images/icons/icon_raw.svg';

const data = [
  {
    title: 'Low Sugar',
    description: 'Jedyna słodycz to ta pochodząca z naturalnych soków owocowych.',
    icon: iconSugar,
  },
  {
    title: 'Gluten Free',
    description: 'W kombuchy nie znajdziesz żadnych składników zawierających gluten.',
    icon: iconGluten,
  },
  {
    title: 'Vegan',
    description: 'Przy powstawaniu świętej herbaty nie ucierpiało żadne zwierzątko.',
    icon: iconVegan,
  },
  {
    title: '100% Raw',
    description: 'Nie pasteryzujemy, ani nie dodajemy sztucznych barwników i aromatów.',
    icon: iconRaw,
  },
];

export const Properties = () => {
  const { isMobile } = useMuiBreakpoints();
  const classes = useStyles();

  const items = data.map((item) => (
    <ListItem className={cn(isMobile ? classes.itemMobile : classes.item)} alignItems={'flex-start'} key={item.title}>
      <ListItemAvatar>
        <Avatar className={classes.avatar} src={item.icon} alt={item.title} />
      </ListItemAvatar>
      <ListItemText
        primary={item.title}
        primaryTypographyProps={{
          color: 'primary',
          variant: 'h4',
        }}
        secondary={item.description}
        secondaryTypographyProps={{
          variant: 'body1',
        }}
      />
    </ListItem>
  ));

  return <List className={classes.root} children={items} />;
};
