import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { SubmitHandler, useForm } from 'react-hook-form';

import { shipping } from '../../state/cart/atoms/shipping';
import { IOrderRequestBody, IUserDataFormValues } from '../../models/Order';
import { isLoadingCart } from '../../state/cart/atoms/isLoading';
import { validators } from '../../misc/validators';
import { cartItems } from '../../state/cart/atoms/cartItems';
import { OrderApi } from '../../services/$order-api';
import { i18n } from '../../lang/pl';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import { getCoupon } from '../../state/coupon/selectors/getCoupon';
import { couponCode } from '../../state/coupon/atoms/couponCode';

const EMPTY_STRING = '';

export const useUserDataForm = () => {
  const { push } = useHistory();
  const [showInvoice, toggleInvoice] = useState(false);
  const [isLoading, setLoading] = useRecoilState(isLoadingCart);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const cart = useRecoilValue(cartItems);
  const shippingId = useRecoilValue(shipping);
  const disabled = !Boolean(shippingId) || isLoading;
  const onInvoiceChange = () => toggleInvoice(!showInvoice);
  const coupon = useRecoilValueLoadable(getCoupon);
  const couponState = useRecoilValue(couponCode);
  const isCoupon = coupon.state === 'hasValue' && coupon.contents?.type !== 'none' && !!couponState;

  const defaultValues: IUserDataFormValues = {
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    street: EMPTY_STRING,
    streetNumber: EMPTY_STRING,
    zip: EMPTY_STRING,
    city: EMPTY_STRING,
    phone: EMPTY_STRING,
    email: (isCoupon ? couponState?.email : EMPTY_STRING) ?? EMPTY_STRING,
    companyName: EMPTY_STRING,
    nip: EMPTY_STRING,
    message: EMPTY_STRING,
    terms: false,
  };

  const validationRules = {
    firstName: validators.firstName(i18n.validators.firstName),
    lastName: validators.lastName(i18n.validators.lastName),
    street: validators.street(i18n.validators.street),
    streetNumber: validators.streetNumber(i18n.validators.streetNumber, i18n.validators.streetNumberCorrect),
    zip: validators.zip(i18n.validators.zip, i18n.validators.zipCorrect),
    city: validators.city(i18n.validators.city),
    phone: validators.phone(i18n.validators.phone),
    email: validators.email(i18n.validators.email, i18n.validators.emailCorrect),
    companyName: validators.companyName(i18n.validators.companyName, showInvoice),
    nip: validators.nip(i18n.validators.nip, i18n.validators.nipCorrect, showInvoice),
    terms: validators.terms(i18n.validators.terms),
  };

  const onSubmit: SubmitHandler<IUserDataFormValues> = (data) => {
    setLoading(true);

    const orderBody: IOrderRequestBody = {
      userData: data,
      cartItems: cart,
      shippingOptionId: shippingId,
      coupon: isCoupon ? couponState : undefined,
    };

    OrderApi.postOrder(orderBody).then((response) => {
      if (response.status.statusCode === 'SUCCESS') {
        window.location.href = response.redirectUri;
      } else {
        push(Routes.error);
      }
    });

    setTimeout(() => setLoading(false), 5000);
  };

  return {
    control,
    disabled,
    disableEmail: isCoupon,
    defaultValues,
    errors,
    showInvoice,
    toggleInvoice: onInvoiceChange,
    isLoading,
    onSubmit: handleSubmit(onSubmit),
    validationRules,
  };
};
