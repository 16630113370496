import React from 'react';

import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import { useController } from 'react-hook-form';

import { IToggleSwitchProps } from './ToggleSwitch';

export const ToggleSwitch = ({ name, control, defaultValue, error, label, rules, ...rest }: IToggleSwitchProps) => {
  const { field } = useController({
    name,
    rules,
    defaultValue,
    control,
  });
  const labelElement = <Typography variant={'body2'} color={error ? 'error' : 'textSecondary'} children={label} />;
  const switchToggle = <Switch {...rest} {...field} color={'primary'} />;

  return <FormControlLabel label={labelElement} control={switchToggle} />;
};
