import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { IDoubleColumnStyleProps } from './DoubleColumn';

interface IStyleProps extends IDoubleColumnStyleProps {
  noMobile: boolean;
}

export const useStyles = makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: ({ noMobile }) => (noMobile ? 'row nowrap' : 'column'),
    },
    leftColumn: {
      marginBottom: ({ noMobile, spacing }) => (noMobile ? 0 : theme.spacing(spacing)),
      paddingRight: ({ noMobile, spacing }: IStyleProps) => (noMobile ? theme.spacing(spacing) / 2 : 0),
    },
    rightColumn: {
      paddingLeft: ({ noMobile, spacing }: IStyleProps) => (noMobile ? theme.spacing(spacing) / 2 : 0),
    },
  })
);
