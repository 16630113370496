import React from 'react';
import { Fab } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

import { IBackButtonProps } from './BackButton';
import { i18n } from '../../../lang/pl';

import { useStyles } from './BackButton.styles';

export const BackButton = ({ action }: IBackButtonProps) => {
  const classes = useStyles();

  return (
    <Fab variant={'extended'} className={classes.fab} onClick={action}>
      <ArrowBackIos />
      {i18n.backButton.label}
    </Fab>
  );
};
