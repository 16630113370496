import React, { FormEvent } from 'react';

import { useRecoilState } from 'recoil';
import { Box, Button, IconButton, List, Typography } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';

import { BackButton } from '../reusable/BackButton';
import { CartItem } from '../CartItem';
import { Coupon } from '../Coupon';
import { SectionTitle } from '../reusable/SectionTitle';
import { Shipping } from '../Shipping';
import { UserData } from '../UserData';
import { i18n } from '../../lang/pl';
import { isCheckout } from '../../state/cart/atoms/isCheckout';
import { useCart } from '../../state/cart/hooks/useCart';

import { useStyles } from './Cart.styles';

export const Cart = () => {
  const classes = useStyles();
  const [checkout, toggleCheckout] = useRecoilState(isCheckout);
  const { cart, coupon, deleteCoupon, disabled, totalCost, shippingCost, subTotal, totalCount, vatValue } = useCart();

  const items = cart.map((item, index) => <CartItem index={index} item={item} key={item.id} />);

  const onShippingSubmit = (event: FormEvent) => {
    event.preventDefault();
    toggleCheckout(!checkout);
  };

  const onBack = () => toggleCheckout(false);

  const empty = <SectionTitle align={'center'} title={i18n.cart.cartEmpty} subtitle={i18n.cart.goShopping} />;

  return Boolean(items.length) ? (
    <Box className={classes.root}>
      <SectionTitle
        title={isCheckout ? i18n.cart.checkout : i18n.cart.cart}
        subtitle={isCheckout ? i18n.cart.last : i18n.cart.review}
      />
      {!checkout && <List children={items} />}
      {!checkout ? (
        <>
          <form onSubmit={onShippingSubmit}>
            <Shipping totalCount={totalCount} />
            <Typography align={'right'}>
              <Typography variant={'overline'} children={i18n.cart.total} />
              {subTotal}
            </Typography>
            <Typography align={'right'}>
              <Typography variant={'overline'} children={i18n.cart.shipping} />
              {shippingCost}
            </Typography>
            <Typography align={'right'}>
              <Typography variant={'overline'} children={i18n.cart.vat} />
              {vatValue}
            </Typography>
            <Typography color={'primary'} variant={'h3'} align={'right'} paragraph={!coupon}>
              <Typography variant={'overline'} children={i18n.cart.payment} />
              {totalCost}
            </Typography>
            {coupon && (
              <Box
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                display={'flex'}
                marginBottom={1}
              >
                <Typography
                  color={'error'}
                  variant={'button'}
                  align={'right'}
                  children={i18n.coupon.discount + coupon}
                  component={'span'}
                />
                <IconButton onClick={deleteCoupon} aria-label={'delete'} edge={'end'}>
                  <DeleteForeverOutlined color={'error'} />
                </IconButton>
              </Box>
            )}
            <Button
              color={'primary'}
              type={'submit'}
              variant={'contained'}
              size={'large'}
              children={i18n.cart.nextButtonLabel}
              disabled={disabled}
              fullWidth
              disableElevation
            />
          </form>
          <Coupon />
        </>
      ) : (
        <>
          <UserData />
          {isCheckout && <BackButton action={onBack} />}
        </>
      )}
    </Box>
  ) : (
    empty
  );
};
