import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomSpacing: {
      marginBottom: theme.spacing(5),
    },
    width: {
      width: '100%',
    },
  })
);
