import axios, { CancelTokenSource } from 'axios';

import catchHttpError from '../../misc/helpers/api/catchHttpError';
import StrapiService from '../../misc/classes/StrapiService';
import getData from '../../misc/helpers/api/getData';
import { IKombuchaResponse } from '../../models/Kombuchas';
import withCacheHeader from '../../misc/helpers/api/withCacheHeader';

export class KombuchasApi extends StrapiService {
  private static cancelTokenKombuchas?: CancelTokenSource;

  public static cancelKombuchas() {
    if (KombuchasApi.cancelTokenKombuchas) {
      KombuchasApi.cancelTokenKombuchas.cancel();
      KombuchasApi.cancelTokenKombuchas = undefined;
    }
  }

  public static getKombuchas(): Promise<IKombuchaResponse[]> {
    return new Promise((resolve, reject) => {
      KombuchasApi.cancelTokenKombuchas = axios.CancelToken.source();

      axios
        .get(
          KombuchasApi.getDataUri('kombuchas'),
          withCacheHeader({
            cancelToken: KombuchasApi.cancelTokenKombuchas.token,
          })
        )
        .then(getData)
        .then(resolve)
        .catch((error) => {
          reject(catchHttpError);
        });
    });
  }
}
