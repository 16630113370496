import { useMemo } from 'react';

import { IValidator } from '../../models/Forms';

export const useLastNameValidator = (requiredMsg: string): IValidator =>
  useMemo(
    () => ({
      required: {
        value: true,
        message: requiredMsg,
      },
    }),
    [requiredMsg]
  );
